// MENU
$("#userAvatar").click(function(){
    $("#userMenu").toggle();
});


// Remove plaeholder on input focus
$(function () {
    $('input,textarea').focus(function () {
        $(this).data('placeholder', $(this).attr('placeholder'))
               .attr('placeholder', '');
    }).blur(function () {
        $(this).attr('placeholder', $(this).data('placeholder'));
    });
});

function getFileData(myFile, $class){
    var file = myFile.files[0];
    var filename = file.name;
    
    console.log(filename, file, $class);
    $('.'+$class).parent().find('.filename').html(filename);
}

// prompt a2hs

// Detects if device is on iOS
const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test( userAgent );
}
// Detects if device is in standalone mode
const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

// var is_opera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
// var is_chrome = !!window.chrome && !is_opera;
// var is_explorer= typeof document !== 'undefined' && !!document.documentMode && !isEdge;
// var is_firefox = typeof window.InstallTrigger !== 'undefined';
var is_safari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);

var userSetToken = function () {
    $.post('/api/user/set-token', function (data) {
        console.log(data);
    });
};